
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import provision from "@/services/provision";

// import eruda from "eruda";
// eruda.init();

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },

  mounted() {
    provision.fetchSites();
    provision.getLocation();
    // this.$store.commit('initchartdata')
    // this.$store.dispatch('setCenter')
  }

});
