import Site from '@/models/Site'
import DataValue from '@/models/DataValue'
import { store } from '@/store'

const axios = require('axios').default;

export default {

    // Fetch sites on page load
    async fetchSites() {
        await axios.get('https://api.reon.cc/api/sites?format=json')
            .then(function (response) {
                // handle success
                Site.insert( response );
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
                // console.log("Always Executed")
        });
    console.log("provision.fetchSites")   
    },

    async fetchSiteData (siteid) {
        await axios.get('https://api.reon.cc/api/waterlevels/'+siteid)
            .then(function (response) {
                // handle success
                DataValue.insert( response );
                store.dispatch('addWaterLevels', [response.data, siteid])
                Site.update({
                    where: siteid,
                    data: {
                        waterlevelmin: DataValue.query().where('SiteID', siteid).min('DataValue'),
                        waterlevelmax: DataValue.query().where('SiteID', siteid).max('DataValue'),
                    }
                })
            })
            .catch(function (error) {
                // handle error
                console.log("provision.fetchSiteData:", error);
            })
            .then(function () {
                // always executed
                // console.log("Always Executed")
        });
    console.log("provision.fetchSiteData:", DataValue.find(siteid), Site.find(siteid))
    },

    getLocation () {
        navigator.geolocation.getCurrentPosition(
        position => {
            localStorage.latitude = position.coords.latitude
            localStorage.longitude = position.coords.longitude
            console.log("provision.getLocation: successfully secured GPS location")

        },
        error => {
            localStorage.latitude = 26.25
            localStorage.longitude = -97.50
            console.log("provision.getLocation:",error.message);
            console.log("provision.getLocation: setting default location")
            console.log("provision.getLocation:", localStorage.latitude, localStorage.longitude)
        },
      )      
    },

    testdata:  {
        labels: ['Test Data', ],
        datasets: [
          {
            data: [{'x': "2022-03-01 20:58:50", 'y': 0.75},
              {'x': "2022-03-03 10:58:50", 'y': 0.50},
              {'x': "2022-03-09 20:58:50", 'y': 0.60},
              {'x': "2022-03-11 20:58:50", 'y': 0.90},
              {'x': "2022-03-18 20:58:50", 'y': 0.80},
              ],
            backgroundColor: '#77CEFF',
            label: 'Test Data',
            hidden: true,
          },
        ],
      },

}