import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/home'
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        name: 'home',
        redirect: '/tabs/home'
      },
      {
        path: 'login',
        component: () => import('@/views/LoginTab.vue')
      },  
      {
        path: 'home',
        component: () => import('@/views/HomeTab.vue')
      },
      {
        path: 'sites',
        component: () => import('@/views/SitesTab.vue')
      },
      {
        path: 'map',
        component: () => import('@/views/MapTab.vue')
      },       
      {
        path: 'data',
        component: () => import('@/views/DataTab.vue')
      },   
      {
        path: 'hecras',
        name: 'ras',
        component: () => import('@/views/HECRASTab.vue'),
        props: true
      },         
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
