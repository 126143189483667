// https://vuex.vuejs.org/guide/typescript-support.html#typing-usestore-composition-function
// https://www.smashingmagazine.com/2020/01/data-components-vue-js/#vuex-application-level-shared-state
// store/index.js
import { createStore } from 'vuex'
import { ref } from "vue";
import Site from '@/models/Site'
import DataValue from '@/models/DataValue'
import provision from "@/services/provision";

export const store = createStore({
  state () {
    return {
      // packageVersion: process.env.PACKAGE_VERSION || '0.9.1',
      user: null,
      token: null,
      loginicon: "at",
        waterleveldatastats: {
          datemin: new Date(),
          // datemax: new Date('2005-01-01T00:00:00Z'),
          datemax: new Date(0),
          waterlevelmin: 100,
          waterlevelmax: -100
        },
        numSites: 0,
        numByState: {},
        mapBounds: {},
        mapCenter: { lat: 36.25, lng: -86.25 },
        mapZoom: 4,
        WaterLevelChartRef: ref(null),
        mylocation: {lat: 26.25, lng: -97.50},
        sites: {},
        waterlevels: {},
        waterleveloptions: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',              
              labels: {
                filter: item => {
                    return item.text != "Test Data"
                }
              }
            },
            title: {
              display: true,
              text: 'Please Select At Least One Station on the Maps Tab to Display Chart',
            },
          },
          scales: {
              x: {
                  type: 'time',
                  time: {
                    unit: "day",
                  },
                  min: new Date(0), //"2022-03-01 20:58:50",
                  max: new Date(),
              }
            },
          },
        colors: ["blue", "green", "red", "black", "purple", "orange", "black", "brown", "yellow"],
        waterleveldatasets:  {
          labels: ['Test Data', ],
          datasets: [
            {
              data: [{'x': "2022-03-01 20:58:50", 'y': 0.75},
                {'x': "2022-03-03 10:58:50", 'y': 0.50},
                {'x': "2022-03-09 20:58:50", 'y': 0.60},
                {'x': "2022-03-11 20:58:50", 'y': 0.90},
                {'x': "2022-03-18 20:58:50", 'y': 0.80},
                ],
              backgroundColor: '#77CEFF',
              label: 'Test Data',
              hidden: true,
            },
          ],
        },
        count: 1
    }
  },
  // getters: {
  //   appVersion: (state) => {
  //     return state.packageVersion
  //   }
  // },
  mutations: {
    togglecharttitle (state) {
      if (state.waterleveldatasets.datasets.length > 1) {
        state.waterleveloptions.plugins.title.text = "Water Level (ft)"
      } else {
        state.waterleveloptions.plugins.title.text = "Please Select At Least One Station on the Maps Tab to Display Chart"
      }
    },
    toggleloginicon (state) {
      if (state.loginicon == "at") {state.loginicon = "person"}
      else {state.loginicon = "at"}
    },
    addsite (state, site) {
      state.sites[site.SiteID] = site
    },
    setsitekey (state, x) {
      let SiteID = x[0]
      let key = x[1]
      let value = x[2]
      state.sites[SiteID][key] = value
    },
    movemap (state, center) {
      state.mapCenter = center
      state.mapZoom = 9
    },
    initchartdata (state) {
      state.waterleveldatasets = state.testdata
      // state.waterleveloptions.scales.x.min = state.waterleveldatastats.datemin
    }
  },

  actions: {

    addDataset ({state}, dataset) {
      if(state.waterleveldatasets['datasets'].indexOf(dataset) === -1) {
        state.waterleveldatasets['datasets'].push(dataset)
        console.log("store.addDataset:", dataset)
      console.log("store.addDataset:", state.waterleveldatasets)
      }
    },

    async addlabel ({state}, label) {
      if(state.waterleveldatasets['labels'].indexOf(label) === -1) {
        state.waterleveldatasets['labels'].push(label)
        // state.waterleveldatasets['labels'].shift()
      }
    },

    async addWaterLevels({state}, [response, siteid]) {
      const sitename = Site.find(siteid)['SiteName']
      const color = state.colors[siteid % state.colors.length]
      state.waterlevels[siteid] = {'data': [], 'backgroundColor': color, 'label': sitename}
      let datemin =  new Date()
      let datemax =  new Date(0)
      for (var i = 0; i < response.length; i++) {
        state.waterlevels[siteid]['data'][i] = {'x':  new Date(response[i]['DateTimeUTC']), 'y': response[i]['DataValue']}
        if (state.waterlevels[siteid]['data'][i]['x'] < datemin) datemin = state.waterlevels[siteid]['data'][i]['x']
        if (state.waterlevels[siteid]['data'][i]['x'] > datemax) datemax = state.waterlevels[siteid]['data'][i]['x']
        }
      Site.update({
        where: siteid,
        data: {
          datemin: datemin,
          datemax: datemax,
          amountdata: response.length
        }
      })
      // commit('setsitekey', [siteid, 'datemin', datemin])
      if (datemin < state.waterleveldatastats.datemin) {
        state.waterleveldatastats.datemin = datemin
        state.waterleveloptions.scales.x.min = datemin
      }
      // commit('setsitekey', [siteid, 'datemax', datemax])      
      if (datemax > state.waterleveldatastats.datemax) {
        state.waterleveldatastats.datemax = datemax
        state.waterleveloptions.scales.x.max = datemax
      }
      console.log("store.addWaterLevels", siteid,state.waterlevels[siteid], Site.find(siteid))      
    },    

    async toggleSiteData ({state, dispatch}, siteid) {
      if (!DataValue.query().where('SiteID', siteid).count()) {
        await provision.fetchSiteData(siteid);
      }
      console.log("store.toggleSiteData:", siteid, state.waterlevels[siteid]); 
      if(state.waterleveldatasets['datasets'].indexOf(state.waterlevels[siteid]) === -1) {
        dispatch('addDataset', state.waterlevels[siteid])
        dispatch('addlabel', Site.find(siteid).SiteName)
      }
      console.log("store.toggleSiteData:", siteid, state.waterleveldatasets);  
    },

    async xxfetchsitedata ({state, commit}, siteid) {
      console.log("Fetching site data for ", siteid);
      let datemin =  new Date()
      let datemax =  new Date(0)
      let waterlevelmin = 100
      let waterlevelmax = -100
      if (typeof state.waterlevels[siteid] == "undefined") {
        try {
          const response = await fetch('https://api.reon.cc/api/waterlevels/'+siteid);
          const json = await response.json();
          console.log('DataValue json', json)
          DataValue.insert({ data: json })
          const sitename = Site.find(siteid)['SiteName']
          console.log('Site Info: ', Site.find(siteid))
          console.log('DataValue: ', DataValue.all())
          const color = state.colors[siteid % state.colors.length]
          state.waterlevels[siteid] = {'data': [], 'backgroundColor': color, 'label': sitename}
          for (var i = 0; i < json.length; i++) { 
            state.waterlevels[siteid]['data'][i] = {'x':  new Date(json[i]['DateTimeUTC']), 'y': json[i]['DataValue']}
            if (state.waterlevels[siteid]['data'][i]['x'] < datemin) datemin = state.waterlevels[siteid]['data'][i]['x']
            if (state.waterlevels[siteid]['data'][i]['x'] > datemax) datemax = state.waterlevels[siteid]['data'][i]['x']
            if (state.waterlevels[siteid]['data'][i]['y'] < waterlevelmin) waterlevelmin = state.waterlevels[siteid]['data'][i]['y']
            if (state.waterlevels[siteid]['data'][i]['y'] > waterlevelmax) waterlevelmax = state.waterlevels[siteid]['data'][i]['y']            
            }
          Site.update({ where: siteid, data: { chart: true } })
          // commit('setsitekey', [siteid, 'chart', true])
          Site.update({ where: siteid, data: { amountdata: json.length } })
          // commit('setsitekey', [siteid, 'amountdata', json.length])
          console.log("fetchsitedata", siteid, state.waterlevels[siteid])
          console.log(Object.keys(state.waterlevels).length)
        } catch (error) {
          console.log(error);
        }
      }
      if(state.waterleveldatasets['datasets'].indexOf(state.waterlevels[siteid]) === -1) {
        commit('adddataset', state.waterlevels[siteid])
        commit('addlabel', Site.find(siteid).SiteName)
        console.log("Added Data", siteid, state.waterlevels[siteid]);
      }

      Site.update({ where: siteid, data: { datemin: datemin } })
      // commit('setsitekey', [siteid, 'datemin', datemin])
      if (datemin < state.waterleveldatastats.datemin) {
        state.waterleveldatastats.datemin = datemin
        state.waterleveloptions.scales.x.min = datemin
      }
      Site.update({ where: siteid, data: { datemax: datemax } })
      // commit('setsitekey', [siteid, 'datemax', datemax])      
      if (datemax > state.waterleveldatastats.datemax) {
        state.waterleveldatastats.datemax = datemax
        state.waterleveloptions.scales.x.max = datemax
      }

      Site.update({ where: siteid, data: { waterlevelmin: waterlevelmin } })
      // commit('setsitekey', [siteid, 'waterlevelmin', waterlevelmin])
      if (waterlevelmin < state.waterleveldatastats.waterlevelmin) {
        state.waterleveldatastats.waterlevelmin = waterlevelmin
      }
      Site.update({ where: siteid, data: { waterlevelmax: waterlevelmax } })
      // commit('setsitekey', [siteid, 'waterlevelmax', waterlevelmax])      
      if (waterlevelmax > state.waterleveldatastats.waterlevelmax) {
        state.waterleveldatastats.waterlevelmax = waterlevelmax
      }      

      console.log("Done fetching site data for ", siteid);
      console.log("Chart Data: ", state.waterleveldatasets)
    },

    removesitedata ( { state, commit }, siteid) {
      Site.update({ where: siteid, data: { chart: false } })
      // commit('setsitekey', [siteid, 'chart', false])      
      let site = Site.find(siteid)

      // Remove Water Level Data from Dataset
      for ( var i = state.waterleveldatasets['datasets'].length; i--; ) {      
        console.log(i, siteid, state.waterleveldatasets['datasets'][i])
        console.log(state.waterleveldatasets['datasets'][i].label, site['SiteName'])
        if (state.waterleveldatasets['datasets'][i].label == site['SiteName']) {
          state.waterleveldatasets['datasets'].splice(i, 1)
          state.waterleveldatasets['labels'].splice(i, 1)
        }
      }
      console.log("Removed site: ", siteid)
      console.log("Chart Data: ", state.waterleveldatasets)
    },

    setCenter ( { state } ) {
      let latmin = 1000
      let latmax = -1000
      let lngmin = 1000
      let lngmax = -1000
      state.numSites = Object.keys(state.sites).length
      state.numByState = {}
      for (const siteid in state.sites) {
        const site = state.sites[siteid]
        const st = site['State']
        // map Bounds
        if (parseFloat(site['Latitude']) <= latmin) { latmin = parseFloat(site['Latitude'])}
        if (parseFloat(site['Longitude']) <= lngmin) { lngmin = parseFloat(site['Longitude'])}
        if (parseFloat(site['Latitude']) >= latmax) { latmax = parseFloat(site['Latitude'])}
        if (parseFloat(site['Longitude']) >= lngmax) { lngmax = parseFloat(site['Longitude'])}   
        //  States
        if (st in state.numByState) {
          state.numByState[st]+= 1
        } else {
          state.numByState[st] = 1
        }
      }
      // state.mapBounds.extend(site['position'])
      state.mapBounds = [ {'lat': latmin, 'lng': lngmin}, {'lat': latmax, 'lng': lngmax} ]
      state.mapCenter = {'lat': (latmax+latmin)/2, 'lng': (lngmax+lngmin)/2} // Something screwy here
      // state.mapCenter = { lat: 36.25, lng: -86.25 }
    },

  }
})
