import { Model } from '@vuex-orm/core'
import DataValue from '@/models/DataValue'

export default class Site extends Model {

  static entity = 'sites'

  static primaryKey = 'SiteID'

  static fields () {
    return {
      SiteID: this.number(null),
      SiteCode: this.string(''),
      SiteName: this.string(''),
      Latitude: this.number(''),
      Longitude: this.number(''),
      LatLongDatumID: this.number(''),
      SiteType: this.string(''),
      Elevation_m: this.string(''),
      VerticalDatum: this.string(''),
      LocalX: this.string(''),
      LocalY: this.string(''),
      LocalProjectionID: this.number(''),
      PosAccuracy_m: this.string(''),
      State: this.string(''),
      County: this.string(''),
      Comments: this.string(''),
      chart: this.boolean(false),
      datemin: this.string(''),
      datemax: this.string(''),
      waterlevelmin: this.number(''),
      waterlevelmax: this.number(''),
      amountdata: this.number('')
    }
  }

  get location () {
    return { lat: parseFloat(this.Latitude), lng: parseFloat(this.Longitude) }
  }
}