import { Model } from '@vuex-orm/core'
import Site from '@/models/Site'

export default class DataValue extends Model {

  static entity = 'datavalues'

//   static primaryKey = 'SiteID'

  static fields () {
    return {
        CensorCode: this.string(''),
        DataValue: this.number(''),
        DateTimeUTC: this.string(''),
        // DateTimeUTC: this.date(null),        
        DerivedFromID: this.string(''),
        LocalDateTime: this.string(''),
        // LocalDateTime: this.date(null),
        MethodID: this.number(''),
        OffsetTypeID: this.string(''),
        OffsetValue: this.string(''),
        QualifierID: this.string(''),
        QualityControlLevelID: this.string(''),
        SampleID: this.string(''),
        SiteID: this.number(''),
        SourceID: this.string(''),
        UTCOffset: this.string(''),
        ValueAccuracy: this.string(''),
        ValueID: this.number(''),
        VariableID: this.number(''),
        Site: this.belongsTo(Site, 'SiteID', 'SiteID'),
    }
  }

  get DateTime () {
    return new Date( this.DateTimeUTC )
  }  
}