import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

// Added by the CLI
import './registerServiceWorker';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import Vue3Geolocation from 'vue3-geolocation';

// https://vuex.vuejs.org/guide/migrating-to-4-0-from-3-x.html#installation-process
import { store } from './store'
// https://vuex-orm.org/guide/prologue/getting-started.html#define-models
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import datePlugin from 'vuex-orm-plugin-date-attribute'
import User from '@/models/User'
import Post from '@/models/Post'
import Site from '@/models/Site'
import DataValue from '@/models/DataValue'

VuexORM.use(datePlugin)

const app = createApp(App)
  // .use(Vuex)
  .use(store)
  .use(Vue3Geolocation)
  .use(IonicVue)
  .use(router);
 
// Create a new instance of Database.
const database = new VuexORM.Database()

// Register Models to Database.
database.register(User)
database.register(Post)
database.register(Site)
database.register(DataValue)

// Create Vuex Store and register database through Vuex ORM.
const storeorm = new Vuex.Store({
  plugins: [VuexORM.install(database)]
})

export default storeorm
  
router.isReady().then(() => {
  app.mount('#app');
});