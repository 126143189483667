
import { defineComponent } from 'vue';
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { download, map, home, statsChart , person, at} from 'ionicons/icons';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'TabsPage',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  computed: mapState([ 
    'loginicon', 
  ]),
  setup() {
    return {
      download, 
      map, 
      home,
      statsChart,
      person,
      at,
    }
  }
});
